import type { MethodResponse } from "openapi-react-query"
import { compareSemVer } from "semver-parser"

import type { $rqViewsApiClient } from "@/api/groups"
import { isDeviceMobile } from "@/constants/devices"

type Device = MethodResponse<typeof $rqViewsApiClient, "get", "/groups/v1/devices">[number]

export function formatOtaDuration(endDate: string | undefined, startDate: string | undefined) {
  try {
    if (!endDate || !startDate) {
      return "-"
    }

    const end = new Date(endDate).getTime()
    const start = new Date(startDate).getTime()
    const diff = Math.max(0, end - start)

    const minutes = Math.floor(diff / 60000)
    const seconds = Math.floor((diff % 60000) / 1000)

    if (minutes > 0) {
      return `${minutes}m ${seconds}s`
    }
    return `${seconds} Seconds`
  } catch {
    return "-"
  }
}

/** Check if the device has an expired CA certificate */
export function isDeviceCAExpired(
  device: Pick<Device, "issuer_ca" | "lib_version" | "created_at"> & { fqbn?: string }
) {
  const creationDate = new Date(device.created_at).getTime()
  const expirationDate = new Date("2025-01-27").getTime()

  const isCreatedBeforeMigrationDate = creationDate < expirationDate

  // ESP32 devices don't have a CA, but must have a 2.3.0 or higher library version
  if (device.fqbn && device.fqbn.includes("esp32")) {
    // If there's no lib_version, we assume it's outdated and warn the user
    return device.lib_version ? compareSemVer(device.lib_version, "2.3.0") === -1 : isCreatedBeforeMigrationDate
  }

  // Old CA Arduino device need to be migrated
  if (device.issuer_ca === "Arduino") {
    return true
  }

  // If the device is mobile, we don't need to do further checks as it doesn't have a lib_version.
  if (isDeviceMobile(device.fqbn)) return false

  // New CA Arduino show warning only if library needs to be updated and the board has been connected at least once
  if (device.issuer_ca === "Arduino_v2") {
    // If it was created before Jan 27 2025 and we have no lib_version, we assume it's outdated and warn the user
    return device.lib_version ? compareSemVer(device.lib_version, "2.3.0") === -1 : isCreatedBeforeMigrationDate
  }

  // If no condition is met, we assume the device is up to date. ESP8266, LoRa, generic...
  return false
}
