export const sketchbookRoot = "$HOME/sketches_v2"

export const sketchNameRegex = {
  // Non global! See https://stackoverflow.com/questions/2630418/javascript-regex-returning-true-then-false-then-true-etc
  validate: /^[a-zA-Z0-9][a-zA-Z0-9_.-]{0,62}$/,
  transformIntoConformant: (str: string) =>
    str
      .normalize("NFD")
      // Replace spaces with underscores
      .replace(/\s/g, "_")
      // Remove invalid characters
      .replace(/[^a-zA-Z0-9_.-]/g, "")
}
