import type { Member } from "@/types"

/**
 * Parses user information from a list of members.
 *
 * @param {string} user_id - The ID of the user to find.
 * @param {Member[]} memberList - The list of members to search through.
 * @returns {{ id: string; username: string; avatar: string }} An object containing the id, username, and avatar of the user.
 */
export function parseUserInfo(user_id: string, memberList: Member[]): { id: string; username: string; avatar: string } {
  // ?: Evaluate if we can short circuit here when we detect the user_id is the current user.

  const member = memberList.find(m => m.id === user_id)
  if (member) {
    return {
      id: member.id,
      username: member.username || member.name || member.email || "-",
      avatar: member.avatar || ""
    }
  }
  return { id: user_id, username: "-", avatar: "" }
}

export function isInTypeGuard<T extends object>(key: PropertyKey | undefined, obj: T): key is keyof T {
  if (key === undefined) return false
  return key in obj
}
