import { snackbar } from "@bcmi-labs/art-ui/components"
import { MY_CLOUD_SPACE } from "@bcmi-labs/cloud-sidebar"
import { createFileRoute, notFound, redirect } from "@tanstack/react-router"
import type { MethodResponse } from "openapi-react-query"

import { $rqFoldersApiClient } from "@/api/groups"
import { $rqIotApiClient } from "@/api/iot"
import { $rqOtaApiClient } from "@/api/ota"
import { getXOrganizationHeaders } from "@/api/utils"
import { isDeviceMobile } from "@/constants/devices"
import { IoTLinks } from "@/constants/links"
import { routeGuard } from "@/lib/routing/beforeLoad"
import { isDeviceCAExpired } from "@/lib/utils/device"
import { openExternalLink } from "@/lib/utils/openExternalLink"

export const Route = createFileRoute("/_authlayout/_header/devices/$id")({
  staticData: {
    onSpaceChange: () =>
      redirect({
        to: "/devices",
        throw: true
      })
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: async ({ context: { queryClient, spaceStorage, headManager }, params, preload }) => {
    const space = spaceStorage.getSpace()

    try {
      // TODO: Remove when https://github.com/openapi-ts/openapi-typescript/pull/1950 is merged
      const deviceData: MethodResponse<typeof $rqIotApiClient, "get", "/iot/v2/devices/{id}"> =
        await queryClient.ensureQueryData(
          $rqIotApiClient.queryOptions("get", `/iot/v2/devices/{id}`, {
            params: { header: getXOrganizationHeaders(space), path: { id: params.id } }
          })
        )

      // Prefetch folder info
      queryClient.prefetchQuery(
        $rqFoldersApiClient.queryOptions("get", "/groups/v1/folders", {
          params: { header: getXOrganizationHeaders(space), query: { child_id: params.id } }
        })
      )

      // Try prefetching the OTA list query, but don't wait for it or error out.
      if (deviceData.ota_compatible) {
        queryClient.prefetchQuery(
          $rqOtaApiClient.queryOptions("get", "/v1/ota", {
            params: {
              header: getXOrganizationHeaders(space),
              query: { device_id: params.id, limit: 500, order: "desc" }
            }
          })
        )
      }

      // Warn if device has an expired CA certificate
      if (isDeviceCAExpired({ ...deviceData, created_at: deviceData.created_at || new Date().toISOString() })) {
        const isMobile = isDeviceMobile(deviceData.fqbn)
        snackbar({
          message: `Security update required: ${isMobile ? "Please update the Arduino IoT Remote app to apply the latest security patches." : "Go to the Sketch tab and upload the latest sketch via USB or OTA"} `,
          variant: "warning",
          actions:
            isMobile || !deviceData.thing
              ? undefined
              : [
                  {
                    text: "update",
                    onClick: () => openExternalLink(IoTLinks.thing.existing(deviceData.thing?.id || "", "sketch"))
                  }
                ]
        })
      }

      if (deviceData.organization_id !== space && space !== MY_CLOUD_SPACE.id) notFound({ throw: true })

      if (!preload) {
        headManager.push({
          title: `${deviceData.name} | Arduino Cloud`
        })
      }
    } catch {
      // Whatever happens, we want to throw a 404.
      notFound({ throw: true })
    }
  }
})
