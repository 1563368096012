import { IconAccountSettingsNormal, IconAccountViewAccessibility, IconUserBriefcaseNormal } from "@arduino/react-icons"
import type { NavigationMenus } from "@bcmi-labs/cloud-sidebar"
import { defaultSidebarNavigation, planUsageLink, spaceSettingsLink } from "@bcmi-labs/cloud-sidebar"

import { TrashIcon, UserGear } from "@/components/icons"
import { deploymentPath } from "@/config"

export const mainNavigation: NavigationMenus = {
  linkGroup: defaultSidebarNavigation
}

export const subNavigation: Record<string, NavigationMenus> = {
  spaceSettings: {
    name: "Space Settings",
    linkGroup: [
      [
        {
          label: "General",
          href: spaceSettingsLink.href, // This links the "General" to the "Space Settings" page
          icon: IconAccountSettingsNormal,
          allowed: spaceSettingsLink.allowed
        },
        {
          label: "Custom Branding",
          href: "/branding",
          icon: IconAccountViewAccessibility,
          allowed: {
            space: ["pro"],
            minor: true
          }
        },
        {
          label: "Manage Members",
          href: "/members",
          icon: UserGear,
          allowed: {
            space: ["edu", "pro"],
            minor: true
          }
        },
        {
          label: "Roles & permissions",
          href: "/roles-permissions",
          icon: IconUserBriefcaseNormal,
          allowed: {
            space: ["edu", "pro"],
            minor: true
          }
        },
        planUsageLink
      ]
    ]
  },
  settings: {
    name: "Settings",
    linkGroup: [
      [
        {
          label: "API Keys",
          href: "/api-keys",
          icon: IconAccountSettingsNormal,
          allowed: {
            space: ["edu", "pro", "private"],
            minor: true
          }
        }
        /*         {
          label: "Trigger notification",
          href: "/triggers-notification",
          icon: IconNatureFlashLightNormal,
          allowed: {
            space: ["edu", "pro", "private"],
            minor: true
          }
        } */
      ]
    ]
  },
  trash: {
    linkGroup: [
      [
        {
          label: "Trash",
          href: "/trash",
          icon: TrashIcon,
          allowed: {
            space: ["edu", "pro", "private"],
            minor: true
          }
        }
      ]
    ]
  }
}

export const IoTLinks = {
  sketch: {
    existing: (username: string, sketch: { id: string; thing_id?: string }) =>
      sketch.thing_id
        ? `${deploymentPath.extRoot}/things/${sketch.thing_id}/sketch`
        : `${deploymentPath.extRoot}/sketches/${username}/${sketch.id}`,
    preview: (sketch: { id: string }) => `${deploymentPath.extRoot}/sketches/${sketch.id}?view-mode=preview`
  },
  dashboard: {
    new: `${deploymentPath.extRoot}/dashboards/new`,
    existing: (id: string) => `${deploymentPath.extRoot}/dashboards/${id}`
  },
  thing: {
    new: (attach?: string) => {
      const searchparams = new URLSearchParams()
      if (attach) searchparams.append("attach", attach)
      return `${deploymentPath.extRoot}/things/new?${searchparams}`
    },
    existing: (id: string, tab: "thing" | "sketch" | "metadata" = "thing") => {
      const map: Record<typeof tab, string> = { thing: "setup", sketch: "sketch", metadata: "metadata" }
      return `${deploymentPath.extRoot}/things/${id}/${map[tab]}`
    }
  },
  trigger: {
    new: `${deploymentPath.extRoot}/triggers/new`,
    existing: (id: string) => `${deploymentPath.extRoot}/triggers/${id}`
  },
  device: {
    new: `${deploymentPath.extRoot}/iot/setup`,
    fwUpdate: (id: string) => `${deploymentPath.extRoot}/iot/update-firmware?deviceId=${id}`,
    onboarding: (VendorID: string, ProductID: string, Name: string, SerialNumber: string) =>
      `${deploymentPath.extRoot}/iot/onboarding?vid=${VendorID}&pid=${ProductID}&port=${Name}&serial=${SerialNumber}`
  },
  templates: {
    apply: (id: string) => `${deploymentPath.extRoot}/iot/templates/${id}`
  }
} as const
