import { createFileRoute } from "@tanstack/react-router"

import { $rqCreateApiClient } from "@/api/create"
import { $rqViewsApiClient } from "@/api/groups"
import { query } from "@/api/query"
import { getXOrganizationHeaders } from "@/api/utils"
import { sketchbookRoot } from "@/constants/sketches"
import { routeGuard } from "@/lib/routing/beforeLoad"

type SearchParams = {
  forcesearch?: boolean
}

export const Route = createFileRoute("/_authlayout/_sidebar/sketches/")({
  staticData: {
    title: "Sketches"
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    forcesearch: typeof search.forcesearch === "boolean" ? search.forcesearch : undefined
  }),
  beforeLoad: async ({ context }) => {
    await routeGuard(context, { allowMinor: true })
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.prefetchQuery(query.space.members(space))

    // Sketch queries
    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/sketches", {
        params: { header: getXOrganizationHeaders(space) }
      })
    )

    // Sketch VFS
    queryClient.prefetchQuery(
      $rqCreateApiClient.queryOptions("get", "/create/v2/files/d/{path}", {
        params: {
          header: getXOrganizationHeaders(space),
          path: { path: sketchbookRoot },
          query: { deep: true }
        }
      })
    )

    // Additional queries
    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/things", {
        params: { header: getXOrganizationHeaders(space) }
      })
    )

    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/devices", {
        params: { header: getXOrganizationHeaders(space) }
      })
    )
  }
})
