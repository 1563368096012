import type { ComponentProps } from "react"

/**
 * Open an external link in a new tab, using a hidden anchor element so that it can be detected by the PWA blocker.
 * @param url The URL to open.
 * @param target The target attribute for the anchor element.
 */
export function openExternalLink(url: string | URL, target?: ComponentProps<"a">["target"]) {
  // Create a new hidden anchor element, append it, and click it.
  const anchor = document.createElement("a")
  anchor.href = url.toString()
  anchor.target = target || (url.toString().startsWith(window.location.origin) ? "_self" : "_blank")
  anchor.rel = "noopener noreferrer"
  anchor.style.display = "none"
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}
