import { isInTypeGuard } from "@/lib/utils/users"
import type { Board } from "@/types"
import type { components } from "@/types/api/groups/v1/views/schema"

// IOT Compatibility list
// ? We don't really care for non-Arduino boards, as this is only used when an arduino board is recognized.
export const IOTCompatibleBoards = [
  // Arduino
  "arduino:samd:mkr1000",
  "arduino:samd:mkrgsm1400",
  "arduino:samd:mkrnb1500",
  "arduino:samd:mkrwan1300",
  "arduino:samd:mkrwan1310",
  "arduino:samd:mkrwifi1010",
  "arduino:samd:nano_33_iot",
  "arduino:mbed_nano:nanorp2040connect",
  "arduino:mbed_nicla:nicla_vision",
  "arduino:mbed_opta:opta",
  "arduino:mbed_giga:giga",
  // Portenta
  "arduino:mbed_portenta:envie_m7",
  "arduino:renesas_portenta:portenta_c33",
  "arduino:mbed_portenta:portenta_x8",
  "arduino:python:portenta_x8",
  // Esp32
  "arduino:esp32:nano_nora",
  // Uno R4 Wifi
  "arduino:renesas_uno:unor4wifi"
] as const

// Remove all keys except img
export const arduinoDeviceDataMap = {
  /*   "arduino:samd:tian": {}, */
  "arduino:samd:nano_33_iot": {
    docs: "hardware/nano-33-iot",
    drawing: "nano-33-iot.png"
  },
  /*   "arduino:samd:mzero_pro_bl_dbg": {},
  "arduino:samd:mzero_pro_bl": {},
  "arduino:samd:mzero_bl": {
    docs: "retired/boards/arduino-m0"
  }, */
  "arduino:samd:mkrzero": {
    docs: "hardware/mkr-zero",
    drawing: "mkr-zero.png"
  },
  "arduino:samd:mkrwifi1010": {
    docs: "hardware/mkr-wifi-1010",
    drawing: "mkr-wifi-1010.png"
  },
  "arduino:samd:mkrwan1310": {
    docs: "hardware/mkr-wan-1310",
    drawing: "mkr-wan-1310.png"
  },
  "arduino:samd:mkrwan1300": {
    docs: "hardware/mkr-wan-1300",
    drawing: "mkr-wan-1300.png"
  },
  "arduino:samd:mkrvidor4000": {
    docs: "hardware/mkr-vidor-4000",
    drawing: "mkr-vidor-4000.png"
  },
  "arduino:samd:mkrnb1500": {
    docs: "hardware/mkr-nb-1500",
    drawing: "mkr-nb-1500.png"
  },
  "arduino:samd:mkrgsm1400": {
    docs: "hardware/mkr-gsm-1400",
    drawing: "mkr-gsm-1400.png"
  },
  "arduino:samd:mkrfox1200": {
    docs: "hardware/mkr-fox-1200",
    drawing: "mkr-fox-1200.png"
  },
  "arduino:samd:mkr1000": {
    docs: "hardware/mkr-1000-wifi",
    drawing: "mkr-1000.png"
  },
  "arduino:samd:arduino_zero_native": {
    docs: "hardware/zero",
    drawing: "zero.png"
  },
  "arduino:samd:arduino_zero_edbg": {
    docs: "hardware/zero",
    drawing: "zero.png"
  },
  "arduino:sam:arduino_due_x_dbg": {
    docs: "hardware/due",
    drawing: "due.png"
  },
  "arduino:sam:arduino_due_x": {
    docs: "hardware/due",
    drawing: "due.png"
  },
  /*   "arduino:mraa:up2": {},
  "arduino:mraa:intel_x86_64": {},
  "arduino:mraa:arm_linux_generic": {}, */
  "arduino:megaavr:uno2018": {
    docs: "hardware/uno-wifi-rev2",
    drawing: "uno-wifi-rev2.png"
  },
  "arduino:megaavr:nona4809": {
    docs: "hardware/nano-every",
    drawing: "nano-every.png"
  },
  /*   "arduino:mbed_rp2040:pico": {}, */
  "arduino:mbed_portenta:portenta_x8": {
    docs: "hardware/portenta-x8",
    drawing: "portenta-x8.png"
  },
  "arduino:python:portenta_x8": {
    docs: "hardware/portenta-x8",
    drawing: "portenta-x8.png"
  },
  "arduino:mbed_portenta:envie_m7": {
    docs: "hardware/portenta-h7",
    drawing: "portenta-h7.png"
  },
  "arduino:mbed_niclabe:nicla_voice": {
    docs: "hardware/nicla-voice",
    drawing: "nicla-voice.png"
  },
  "arduino:mbed_nicla:nicla_vision": {
    docs: "hardware/nicla-vision",
    drawing: "nicla-vision.png"
  },
  "arduino:mbed_nicla:nicla_sense": {
    docs: "hardware/nicla-sense-me",
    drawing: "nicla-sense-me.png"
  },
  "arduino:mbed_nano:nanorp2040connect": {
    docs: "hardware/nano-rp2040-connect",
    drawing: "nano-rp2040-connect.png"
  },
  "arduino:mbed_nano:nano33ble": {
    docs: "hardware/nano-33-ble",
    drawing: "nano-33-ble.png"
  },
  "arduino:mbed_opta:opta": {
    docs: "hardware/opta",
    drawing: "opta.png"
  },
  "arduino:mbed_edge:edge_control": {
    docs: "hardware/edge-control",
    drawing: "edge-control.png"
  },
  "arduino:avr:yunmini": {
    docs: "retired/boards/arduino-yun-mini",
    drawing: "yun.png"
  },
  "arduino:avr:yun": {
    docs: "retired/boards/arduino-yun",
    drawing: "yun.png"
  },
  "arduino:avr:unowifi": {
    docs: "retired/boards/arduino-uno-wifi",
    drawing: "uno-wifi-rev2.png" // Missing rev1
  },
  "arduino:avr:unomini": {
    docs: "hardware/uno-mini-le",
    drawing: "uno-mini-le.png"
  },
  "arduino:avr:uno": {
    docs: "hardware/uno-rev3",
    drawing: "uno-rev3.png"
  },
  /*   "arduino:avr:robotMotor": {},
  "arduino:avr:robotControl": {},
  "arduino:avr:pro": {},
  "arduino:avr:one": {}, */
  "arduino:avr:nano": {
    docs: "hardware/nano",
    drawing: "nano.png"
  },
  /*   "arduino:avr:mini": {}, */
  "arduino:avr:micro": {
    docs: "hardware/micro",
    drawing: "micro.png"
  },
  /*   "arduino:avr:megaADK": {}, */
  "arduino:avr:mega": {
    docs: "hardware/mega-2560",
    drawing: "mega-2560.png"
  },
  /*   "arduino:avr:lilypad": {},
  "arduino:avr:leonardoeth": {}, */
  "arduino:avr:leonardo": {
    docs: "hardware/leonardo",
    drawing: "leonardo.png"
  },
  /*   "arduino:avr:gemma": {},
  "arduino:avr:fio": {}, */
  "arduino:avr:ethernet": {
    docs: "hardware/ethernet-shield-rev2",
    drawing: "ethernet-shield-rev2.png"
  },
  /*   "arduino:avr:esplora": {},
  "arduino:avr:diecimila": {},
  "arduino:avr:circuitplay32u4cat": {},
  "arduino:avr:chiwawa": {},
  "arduino:avr:bt": {},
  "arduino:avr:atmegang": {},
  "arduino:avr:LilyPadUSB": {}, */
  "arduino:mbed_nano:nano33blesense": {
    docs: "hardware/nano-33-ble-sense",
    drawing: "nano-33-ble.png"
  },
  "arduino:mbed_giga:giga": {
    docs: "hardware/giga-r1-wifi",
    drawing: "giga-r1-wifi.png"
  },
  "arduino:renesas_portenta:portenta_c33": {
    docs: "hardware/portenta-c33",
    drawing: "portenta-c33.png"
  },
  "arduino:renesas_uno:unor4wifi": {
    docs: "hardware/uno-r4-wifi",
    drawing: "uno-r4-wifi.png"
  },
  "arduino:renesas_uno:minima": {
    docs: "hardware/uno-r4-minima",
    drawing: "uno-r4-minima.png"
  },
  "arduino:esp32:nano_nora": {
    docs: "hardware/nano-esp32",
    drawing: "nano-esp32.png"
  }
} as const

/**
 * Generate a device image path based on the FQBN.
 *
 * @param fqbn
 * @returns
 */
export function getDeviceImgPath(
  device:
    | {
        type: string
        fqbn?: string
      }
    | undefined
) {
  const root = "/images/devices/boards"

  // FQBN is undefined when device is phone.
  if (device?.fqbn === undefined && device?.type === "phone") {
    return `${root}/device-phone.svg`
  }

  const mapped = isInTypeGuard(device?.fqbn, arduinoDeviceDataMap)
    ? arduinoDeviceDataMap[device.fqbn].drawing
    : undefined

  if (mapped) {
    return `${root}/${mapped}`
  }

  if (device?.fqbn === "device:generic:manual") {
    return `${root}/device-generic-manual.svg`
  }

  if (device?.fqbn?.startsWith("esp32")) {
    return `${root}/device-esp32.svg`
  }

  return `${root}/device-generic.svg`
}

export const isDeviceLora = (device: { fqbn: string | undefined; type: string }) =>
  device.type === "lora-device" || (device.fqbn && /arduino:samd:mkrwan13[01]0$/.test(device.fqbn))

export const isDeviceMobile = (fqbn: string | undefined) => !fqbn
export const isDeviceManual = (fqbn: string | undefined) => fqbn === "device:generic:manual"

/**
 * Get the device name based on the device type. Requires a board definition set to be passed in.
 *
 * @param boardsDef
 * @param device
 * @returns
 */
export const getBoardDeviceName = (
  boardsDef: Record<string, Board>,
  device?: {
    fqbn?: string
    type: string
  }
) => {
  if (!device) return "-"
  if (device.type === "lora-device") return "LoRaWAN device"
  if (!device.fqbn) return "Mobile phone"

  return boardsDef[device.fqbn]?.name || "-"
}

export const isMobileOrManualDevice = (fqbn: string | undefined) => isDeviceMobile(fqbn) || isDeviceManual(fqbn)

/*
 * ====================
 * === Device Constants ===
 * ====================
 */

export const DeviceEventType = {
  r_status: "r_status",
  r_thingTopicStatus: "r_thingTopicStatus"
} as const

export const DeviceEventValue = {
  CONNECTED: "CONNECTED",
  SUBSCRIBED: "SUBSCRIBED",
  DISCONNECTED: "DISCONNECTED",
  DENY_SUBSCRIBED: "DENY_SUBSCRIBED"
} as const

export const ConnectionTypesNames: Record<components["schemas"]["Device"]["connection_type"], string> = {
  wifi: "WiFi",
  eth: "Ethernet",
  wifiandsecret: "WiFi (& secret)",
  gsm: "GSM",
  nb: "NB",
  lora: "LoraWAN",
  catm1: "CAT-M1",
  cellular: "Cellular"
} as const
