import type { MethodResponse } from "openapi-react-query"
import createClient from "openapi-react-query"

import { unwrapResponse } from "@/api/utils/NetworkError"
import { sketchbookRoot } from "@/constants/sketches"
import type { paths as createPaths } from "@/types/api/create/schema"

import { authFetch, createApiClient, getXOrganizationHeaders } from "./utils"

// Vanilla API client
export const $createApiClient = createApiClient<createPaths>({ baseUrl: import.meta.env.VITE_API_URL })

// React-Query client
export const $rqCreateApiClient = createClient($createApiClient)

/*
 * =====================
 * Manual API functions
 *
 * These functions are manually written and are not generated from the OpenAPI schema.
 * =====================
 */

/** Get sketch tree from path downwards. Required as slashes are not allowed by path serialization in OpenAPI. */
export async function getSketchTree(spaceId: string, path = sketchbookRoot) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/d/${path}?deep=true`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceId)
  })
  return unwrapResponse<MethodResponse<typeof $rqCreateApiClient, "get", "/create/v2/files/d/{path}">>(res)
}

/** Read sketch file content. Required as slashes are not allowed by path serialization in OpenAPI. */
export async function getSketchFile(spaceId: string, path: string) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/f/${path}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceId)
  })
  return unwrapResponse<MethodResponse<typeof $rqCreateApiClient, "get", "/create/v2/files/f/{path}">>(res)
}

/** Directly create a sketch file. Required as slashes are not allowed by path serialization in OpenAPI. */
export async function createSketchFile(spaceId: string, path: string, data: string) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/f/${sketchbookRoot}/${path}`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceId),
    body: JSON.stringify({ data })
  })
  return unwrapResponse<MethodResponse<typeof $rqCreateApiClient, "post", "/create/v2/files/f/{path}">>(res)
}
