type ToursMap = {
  [key: string]: {
    minVer?: {
      /**
       * The version number that the walkthrough was introduced in.
       */
      ver: string
      /**
       * Whether to skip the first visit or not (i.e. if user is user is landing on the app for the first time after the tour was introduced).
       */
      skipFirstVisit?: boolean
    }
  }
}

export const toursMap = {
  "welcome-tour": {
    minVer: {
      ver: "1.0.0",
      skipFirstVisit: false
    }
  },
  "changelog-tour": {
    minVer: {
      ver: "2.3.0",
      skipFirstVisit: true
    }
  }
} as const satisfies ToursMap

export const defaultFlags = {
  "shared-sketches-banner": true,
  "create-space-banner": true,
  "getting-started-banner": true
} as const satisfies Record<string, boolean>
