import type { LiteralToPrimitiveDeep } from "type-fest"
import * as buildinfo from "virtual:buildinfo"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

import { useRecentsStore } from "@/store/recents"
import { defaultFlags, toursMap } from "@/store/userstorage/flags"

/** "request-tour" is used to trigger the tour from a secondary position */
type RequestableTourState = boolean | "request-tour"

const LATEST_VERSION = 2

type UserStorageStore = {
  flags: LiteralToPrimitiveDeep<typeof defaultFlags>
  setFlag: (key: keyof typeof defaultFlags, value: boolean) => void
  tours: Record<
    keyof typeof toursMap,
    {
      viewed: RequestableTourState
      viewed_at?: {
        build: string
        date: string
      }
    }
  >
  setTourViewed: (key: keyof typeof toursMap, viewed: RequestableTourState) => void
  lastBuild?: typeof buildinfo
  /**
   * Internal method to sync the state of the walkthroughs with the current build version.
   */
  _syncWalkthroughs: (prev: typeof buildinfo | undefined) => void
  _updateBuild: () => void
}

export const useUserStorageStore = create<UserStorageStore>()(
  persist(
    (set, _get, _) => ({
      flags: {
        // These will be automatically set, whether the user already has a saved storage or not.
        // merge() will take care of updating the state with the persisted values, if any.
        "create-space-banner":
          localStorage.getItem("arduino::hideCreateBanner") !== "true" || defaultFlags["create-space-banner"],
        "shared-sketches-banner":
          localStorage.getItem("arduino::sharedsketches-banner") !== "true" || defaultFlags["shared-sketches-banner"],
        "getting-started-banner":
          localStorage.getItem("getting-started") !== "true" || defaultFlags["getting-started-banner"]
      },
      setFlag: (key, value) => set(state => ({ ...state, flags: { ...state.flags, [key]: value } })),
      tours: {
        "welcome-tour": {
          viewed: false
        },
        "changelog-tour": {
          viewed: false
        }
      },
      setTourViewed: (key, viewed) =>
        set(state => ({ ...state, tours: { ...state.tours, [key]: tourStateUpdater(key, viewed) } })),
      _syncWalkthroughs: prev =>
        set(state => ({
          ...state,
          tours: {
            ...state.tours,
            ...Object.entries(toursMap).reduce(
              (acc, [key, { minVer }]) => {
                acc[key as keyof typeof toursMap] = state.tours[key as keyof typeof toursMap]

                const step = state.tours[key as keyof typeof toursMap]

                // Check if this step requires a forced view on first visit (check for tour validity)
                if (!prev && minVer.skipFirstVisit) {
                  // ! PRE: Edge case where you have no previous build, but you visited the welcome tour.
                  acc[key as keyof typeof toursMap] = tourStateUpdater(
                    key as keyof typeof toursMap,
                    !isBuildNewer(buildinfo.version, minVer.ver) ? true : !state.tours["welcome-tour"].viewed
                  )
                  return acc
                }

                if (!isBuildNewer(buildinfo.version, minVer.ver)) {
                  // Previous build: disable the tour.
                  acc[key as keyof typeof toursMap] = tourStateUpdater(key as keyof typeof toursMap, true)
                  return acc
                }

                // Equal or newer build, and has been viewed from a previous build: reset the tour.
                if (
                  prev &&
                  (isBuildNewer(prev.version, minVer.ver) || buildinfo.version === minVer.ver) &&
                  !!step.viewed_at &&
                  !isBuildNewer(step.viewed_at.build, minVer.ver)
                ) {
                  acc[key as keyof typeof toursMap] = tourStateUpdater(key as keyof typeof toursMap, false)
                  return acc
                }

                return acc
              },
              {} as UserStorageStore["tours"]
            )
          }
        })),
      _updateBuild: () => {
        set(state => ({ ...state, lastBuild: buildinfo }))
      }
    }),
    {
      name: "cloudhome-userstorage",
      storage: createJSONStorage(() => localStorage),
      version: LATEST_VERSION,
      migrate: (persistedState, version) => {
        if (version !== LATEST_VERSION) {
          console.warn("User storage version mismatch, running migration...")
          return versionMigrator(persistedState, version)
        }
        return persistedState
      },
      merge: (persistedState, currentState) => {
        // If no persisted state is found, run the initial migration
        if (!persistedState) {
          return runInitialMigration(currentState)
        }

        return { ...currentState, ...persistedState }
      },
      onRehydrateStorage: () => (st, error) => {
        if (error || !st) {
          console.error("Error re-hydrating user storage", error)
          return
        }

        // If the build has changed, sync the store
        if (
          !st.lastBuild ||
          st.lastBuild.version !== buildinfo.version ||
          st.lastBuild.sha !== buildinfo.sha ||
          new Date(st.lastBuild.time).getTime() !== new Date(buildinfo.time).getTime()
        ) {
          st._syncWalkthroughs(st.lastBuild)
          st._updateBuild()
        }
      }
    }
  )
)

/*
 * ==============================
 * Helpers
 * ==============================
 */

// Shorthand for updating the state of a tour
function tourStateUpdater(key: keyof typeof toursMap, viewed: RequestableTourState) {
  return {
    viewed,
    viewed_at: viewed === true ? { build: buildinfo.version, date: new Date().toISOString() } : undefined
  }
}

function isBuildNewer(previous: string, next: string) {
  return previous.localeCompare(next, undefined, { numeric: true }) !== -1
}

/**
 * This function is used to run the initial migration of the user storage, from the previous version to the current one.
 * For new users, this function will return the default values.
 * @returns
 */
function runInitialMigration(state: UserStorageStore) {
  return {
    ...state,
    tours: {
      ...state.tours,
      "welcome-tour": {
        viewed: localStorage.getItem("arduino::walkthrough-finished") === "true",
        viewed_at:
          localStorage.getItem("arduino::walkthrough-finished") === "true"
            ? {
                build: buildinfo.version,
                date: new Date().toISOString()
              }
            : undefined
      }
    }
  }
}

// Recursive function to migrate the user storage from one version to the next
function versionMigrator(persistedState: unknown, version: number) {
  // Select migrator based on version.
  switch (version) {
    case 0:
      // Version 1 changed the removed the recents object and moved it to a separate store
      if (persistedState && typeof persistedState === "object" && "recents" in persistedState) {
        type RecentsData = {
          userid: string
          data: Record<string, string>[]
        }
        const { userid, data } = persistedState.recents as RecentsData
        // eslint-disable-next-line no-param-reassign
        delete persistedState.recents

        useRecentsStore.setState(old => ({
          ...old,
          recents: {
            userid,
            data: data
              .map(item => {
                // eslint-disable-next-line no-param-reassign
                if ("type" in item && typeof item.type === "string") item.type = item.type.toUpperCase()
                return item
              })
              // Remove templates from the recents
              .filter(item => item.type !== "TEMPLATE") as any // eslint-disable-line @typescript-eslint/no-explicit-any
          }
        }))
        return versionMigrator(persistedState, 1)
      }
      throw new Error("Invalid state for version 0")
    case 2:
      // Version 2 reworked the build object from the user storage
      if (
        persistedState &&
        typeof persistedState === "object" &&
        "build" in persistedState &&
        "previousBuild" in persistedState
      ) {
        const state = { ...persistedState, lastBuild: persistedState.previousBuild }
        delete state.build
        delete state.previousBuild
        return versionMigrator(persistedState, 2)
      }
      throw new Error("Invalid state for version 1")
    default:
      return persistedState
  }
}
